import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import Product from '../components/Product'
import Crowd from '../components/Crowd'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import Verified from '../components/Verified'
import SEO from '../components/SEO'

const PostTemplate = ({ data, pageContext }) => {
  const { title, metaDescription, heroImage, alternateImage, body, publishDate, tags, productName, productLink, productPrice, vendorName, crowdSourceTitle, crowdSourceLink, crowdQuoteText1, crowdQuoteLink1, crowdQuoteText2, crowdQuoteLink2, crowdQuoteText3, crowdQuoteLink3, verifiedBody } =
    data.contentfulPost

  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext

  let ogImage
  try {
    ogImage = heroImage.gatsbyImageData.images.fallback.src
  } catch (error) {
    ogImage = null
  }


  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
      />
      <Hero title={title} image={heroImage} height={'30vh'} />
      <Container>
        {tags && <TagList tags={tags} basePath={basePath} />}
        <PostDetails
          date={publishDate}
          timeToRead={body.childMarkdownRemark.timeToRead}
        />
        <PageBody body={body} title={title} productName = {productName} productLink={productLink} crowdSourceTitle={crowdSourceTitle} crowdSourceLink={crowdSourceLink}  />
        <Product productName = {productName} productLink={productLink} productPrice={productPrice} image={alternateImage} vendorName = {vendorName} />
        <Crowd crowdSourceTitle={crowdSourceTitle} crowdQuoteText1 = {crowdQuoteText1} crowdQuoteLink1 = {crowdQuoteLink1} crowdQuoteText2 = {crowdQuoteText2} crowdQuoteLink2 = {crowdQuoteLink2} crowdQuoteText3 = {crowdQuoteText3} crowdQuoteLink3 = {crowdQuoteLink3} />
        <Verified verifiedBody={verifiedBody} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        gatsbyImageData(width: 1800, placeholder: BLURRED)
      }
      alternateImage{
        title
        gatsbyImageData(width:300, placeholder: BLURRED)
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
      productName
      productLink
      productPrice
      vendorName
      crowdSourceTitle
      crowdSourceLink
      crowdQuoteText1
      crowdQuoteLink1
      crowdQuoteText2
      crowdQuoteLink2
      crowdQuoteText3
      crowdQuoteLink3
      verifiedBody {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
