import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StaticImage } from 'gatsby-plugin-image'

const Wrapper = styled.div`

  border: 4px solid ${(props) => props.theme.colors.secondary};
  border-radius: 5px;
  margin: 0 auto 2em;
  padding: 0.25em;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  transition: background 0.2s;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 auto 1em;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 32%')};
  }

  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 50%;
    margin: auto;
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
    transition: 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0.5em 0em 0em;
    float: left;
  }

  h1 {
    font-size: 1.5em;
    padding: 0.5em 0 1em;

  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1em;
  }

`

const VerifiedBody = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  clear: left;
  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
  }

  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1em;
  }

  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }
  img {
    resize-mode: contain;
    width: 50%
  }
`

const Star = styled.img`
  resize-mode: cover;
  height: 50px;
  width: 50px;
  margin: 1em 1em 0em 1em;
  float: left;
`
const Verified = props => {

  if(props.verifiedBody) {
    return <Wrapper>
              <Star src="/images/star.png" alt="A star" /><h1>We've verified it!</h1>
              <VerifiedBody dangerouslySetInnerHTML={{ __html: props.verifiedBody.childMarkdownRemark.html }} />
      </Wrapper>
  } else {
    return null
  }
}

export default Verified
