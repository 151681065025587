import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const Wrapper = styled.div`

  border: 4px solid ${(props) => props.theme.colors.secondary};
  border-radius: 5px;
  margin: 0 auto 2em;
  padding: 0.25em;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  transition: background 0.2s;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 auto 1em;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 32%')};
  }

  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 50%;
    margin: auto;
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
    transition: 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: auto;
  }

  h1 {
    font-size: 1.5em;
    padding: 0.5em 0.5em 1em;
  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1em;
  }

`
const ProductButton = styled.p`

border: 3px solid ${(props) => props.theme.colors.highlight};
border-radius: 5px;
max-width: ${props => props.theme.sizes.maxWidthCentered};
transition: background 0.2s;
@media screen and (min-width: ${(props) => props.theme.responsive.small}) {
  flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 49%')};
  margin: 0 auto 1em;
}
@media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
  flex: ${(props) => (props.featured ? '0 0 100%' : '0 0 32%')};
}

background: ${props => props.theme.colors.primary};
color: white;
padding: 0.5em;
&:hover {
  background: ${(props) => props.theme.colors.highlight};
}

font-weight: 600;
line-height: 1.25;
margin: 1 0 1rem 0;
font-size: 1em;
`

const StyledImg = styled(GatsbyImage)`
  border-radius: 15px;
  border: 3px solid ${(props) => props.theme.colors.highlight};
  resize-mode: cover;
  height: 200px;
  width: 200px;
  margin: 1em auto 1em;
`

const Product = props => {
  return <Wrapper>
            <h1>Where to get it</h1>
            <Link to={`${props.productLink}`} target="_blank"><h2>{props.productName}</h2></Link>
            <Link to={`${props.productLink}`} target="_blank">
              <StyledImg
              image={props.image.gatsbyImageData}
              alt={props.image.title}
              backgroundColor={'#eeeeee'}/></Link>
            <Link to={`${props.productLink}`} target="_blank"><ProductButton>Available on {props.vendorName} for about {props.productPrice}</ProductButton></Link>
    </Wrapper>
}

export default Product
